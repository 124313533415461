var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Toolbar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [[_c('Breadcrumb',{attrs:{"title":_vm.getFlow.name}})],_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.popupInfoDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('flow_setting')))])]),_c('PopupInfo',{attrs:{"dialog":_vm.popupInfoDialog,"flowID":_vm.getFlow.id,"flowName":_vm.getFlow.name,"flowDescription":_vm.getFlow.description,"image":_vm.getFlow.previewimage,"isFlowReadOnly":_vm.isFlowReadOnly},on:{"close":function($event){_vm.popupInfoDialog = false}}})]},proxy:true},{key:"controllers",fn:function(){return [(_vm.getRunState)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"left":"","large":"","color":"warning"}},on),[_vm._v("mdi-alert")])]}}],null,false,334771066)},[_c('span',[_vm._v(_vm._s(_vm.$t('dont_edit')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"elevation":"1","color":_vm.getRunState ? 'green' : _vm.playButtonColor,"outlined":!_vm.$vuetify.theme.dark && !_vm.getRunState,"rounded":"","disabled":_vm.loading || _vm.deleteBrickLoading || _vm.isBrickUpdateLoding || _vm.copyBrickLoading,"loading":_vm.loading || _vm.isBrickUpdateLoding || _vm.deleteBrickLoading || _vm.copyBrickLoading},on:{"click":function($event){return _vm.$emit('change-run-state')}}},on),[_c('v-icon',{attrs:{"color":_vm.getRunState ? 'white' : _vm.$vuetify.theme.dark ? 'white' : 'black'}},[_vm._v(" "+_vm._s(_vm.getRunState ? 'mdi-stop' : 'mdi-play')+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(!_vm.getRunState ? _vm.$t('start_flow') : _vm.$t('stop_flow')))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 mr-1",attrs:{"disabled":_vm.isFlowReadOnly || _vm.deleteBrickLoading || _vm.loading || _vm.copyBrickLoading,"color":_vm.playButtonColor,"outlined":!_vm.$vuetify.theme.dark,"loading":_vm.deleteBrickLoading,"rounded":""},on:{"click":function($event){return _vm.deleteElementClicked()}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v(_vm._s(!_vm.getRunState ? _vm.$t('delete_selected') : _vm.$t('stop_flow_first')))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 mr-1",attrs:{"disabled":_vm.isFlowReadOnly || _vm.deleteBrickLoading || _vm.loading || _vm.copyBrickLoading
                    || (!_vm.ifAnyBrickSelected()),"color":_vm.playButtonColor,"outlined":!_vm.$vuetify.theme.dark,"loading":_vm.copyBrickLoading,"rounded":""},on:{"click":function($event){return _vm.copyElementClicked()}}},on),[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'black'}},[_vm._v(" mdi-content-copy ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(!_vm.getRunState ? _vm.$t('copy_selected') : _vm.$t('stop_flow_first')))])]),_c('v-btn-toggle',{staticClass:"ml-3",attrs:{"rounded":""}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","small":"","fab":""},on:{"click":function($event){return _vm.zoomIn($event)}}},on),[_c('v-icon',[_vm._v("mdi-magnify-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('zoom_in')))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","small":"","fab":"","outlined":""},on:{"click":function($event){return _vm.resetZoom($event)}}},on),[_vm._v("1 : 1")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('default_zoom')))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","small":"","fab":""},on:{"click":function($event){return _vm.zoomOut($event)}}},on),[_c('v-icon',[_vm._v("mdi-magnify-minus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('zoom_out')))])])],1),_c('v-btn-toggle',{staticClass:"ml-3",attrs:{"rounded":"","mandatory":""},on:{"change":function($event){return _vm.changeConnectionStyle($event)}},model:{value:(_vm.connectionStyle),callback:function ($$v) {_vm.connectionStyle=$$v},expression:"connectionStyle"}},[_c('v-btn',{attrs:{"value":"spline","depressed":"","small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-vector-curve")])],1),_c('v-btn',{attrs:{"value":"circuit","depressed":"","small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-vector-polyline")])],1)],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"depressed":"","color":_vm.playButtonColor,"outlined":!_vm.$vuetify.theme.dark,"rounded":""},on:{"click":function($event){return _vm.toggleCanvasGrids()}}},on),[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'black'}},[_vm._v(" "+_vm._s(!_vm.shouldShowGrids ? 'mdi-grid' : 'mdi-grid-off')+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(!_vm.shouldShowGrids ? _vm.$t('enable-grid') : _vm.$t('disable-grid')))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"depressed":"","outlined":!_vm.$vuetify.theme.dark,"color":_vm.playButtonColor,"rounded":""},on:{"click":function($event){return _vm.showLogsClicked()}}},on),[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'black'}},[_vm._v(" mdi-clipboard-text ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('view_logs')))])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }