var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center px-4 py-2"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.isSource ? 'Source' : 'Target')+" ")]),_c('v-spacer')],1),_c('v-divider',{staticClass:"mx-4"}),(_vm.isAddOrEditMode)?_c('v-scroll-x-transition',{attrs:{"hide-on-leave":""}},[_c('v-card-text',[_c('AddFieldInMap',{attrs:{"schemaToEdit":_vm.schemaToEdit,"item":_vm.item,"brick":_vm.brick,"port":_vm.port},on:{"cancel":function($event){_vm.isAddOrEditMode = false}}})],1)],1):_c('v-scroll-x-transition',[_c('v-card-text',[_c('v-treeview',{attrs:{"dense":"","item-key":"name","items":_vm.schemas,"item-children":"elements","hoverable":"","open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"ml-2 pt-0 mt-0 flex-grow-1",attrs:{"dense":"","multiple":"","value":item,"disabled":item.hideCheckBox || _vm.isSelected(item) || _vm.isChildSelected(item) || _vm.isFlowReadOnly,"indeterminate":_vm.isChildSelected(item),"hide-details":""},on:{"click":function($event){return _vm.select(item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"subtitle-2 font-weight-regular text-truncate",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text',staticStyle:{"max-width":"10rem"}},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"mx-4 font-italic\n                        grey--text subtitle-2 font-weight-regular text-truncate",staticStyle:{"max-width":"10rem"}},on),[_vm._v("("+_vm._s(item.isTypeLibrary ? item.typeLibraryName : item.type)+")")])]}}],null,true)},[_vm._v(" Type is "+_vm._s(item.isTypeLibrary ? item.typeLibraryName : item.type)+" ")])]},proxy:true}],null,true),model:{value:(_vm.elementSelection),callback:function ($$v) {_vm.elementSelection=$$v},expression:"elementSelection"}}),(
                  ((_vm.isExtendable(item) || _vm.isEditable(item)) && !_vm.isFlowReadOnly) ||
                    item.isTypeLibrary || _vm.isTypeEditable(item.type)
                )?[(_vm.isExtendable(item))?_c('v-btn',{class:{ 'show-btns': hover, 'hide-btns': !hover },attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.showForm(_vm.ADD_MODE, item)}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e(),(_vm.isEditable(item)
                    || item.isTypeLibrary
                    || _vm.isTypeEditable(item.type))?_c('v-btn',{class:{ 'show-btns': hover, 'hide-btns': !hover, 'my-1': 'my-1' },attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.showForm(_vm.EDIT_MODE, item)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(_vm.isEditable(item))?_c('v-btn',{class:{ 'show-btns': hover, 'hide-btns': !hover },attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.deleteField({ brick: _vm.brick, port: _vm.port, item: item })}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-delete ")])],1):_vm._e(),_c('v-divider',{staticClass:"my-3",attrs:{"vertical":""}})]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'show-btns': hover, 'hide-btns': !hover, 'my-1': 'my-1' },attrs:{"disabled":_vm.isFlowReadOnly,"color":"primary","icon":""},on:{"click":function($event){return _vm.showForm(_vm.ADD_TO_BUFFER, item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-database-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('add_to_buffer')))])])],2)]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }