var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider',{staticClass:"mx-4"}),_c('div',{staticClass:"d-flex justify-space-between align-center px-4 py-2"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('constants'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.isFlowReadOnly},on:{"click":function($event){return _vm.showForm(_vm.ADD_MODE, '')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('add_new_constant'))+" ")],1)],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.constants.length === 0),expression:"constants.length === 0"}],staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('no_constants'))+" ")]),_c('v-dialog',{attrs:{"width":"600","transition":"scroll-y-transition"},model:{value:(_vm.isAddOrEditMode),callback:function ($$v) {_vm.isAddOrEditMode=$$v},expression:"isAddOrEditMode"}},[(_vm.isAddOrEditMode)?_c('ConstantBuilder',{attrs:{"constantToEdit":_vm.constantToEdit,"dialog":_vm.isAddOrEditMode},on:{"cancel":function($event){return _vm.closeDialog()}}}):_vm._e()],1),_c('v-treeview',{attrs:{"dense":"","item-key":"id","items":_vm.constants,"item-children":"elements","hoverable":"","open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"ml-2 pt-0 mt-0 flex-grow-1",attrs:{"dense":"","value":item,"disabled":_vm.isConstantSelected(item) ||
                  item.hideCheckBox ||
                  _vm.isFlowReadOnly ||
                  _vm.isChildSelected(item),"indeterminate":_vm.isChildSelected(item),"hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.select(item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({staticClass:"subtitle-2 font-weight-regular text-truncate",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text',staticStyle:{"max-width":"10rem"}},on),[_vm._v(" "+_vm._s(item.value ? ((item.name) + " : " + (item.value)) : item.name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.value ? ((item.name) + " : " + (item.value)) : item.name)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({staticClass:"mx-4 font-italic\n                        grey--text subtitle-2 font-weight-regular text-truncate",staticStyle:{"max-width":"10rem"}},on),[_vm._v("("+_vm._s(item.isTypeLibrary ? item.typeLibraryName : item.type)+")")])]}}],null,true)},[_vm._v(" Type is "+_vm._s(item.isTypeLibrary ? item.typeLibraryName : item.type)+" ")])]},proxy:true}],null,true),model:{value:(_vm.selectedConstants),callback:function ($$v) {_vm.selectedConstants=$$v},expression:"selectedConstants"}}),(!_vm.isFlowReadOnly && !leaf)?_c('div',{staticClass:"d-flex"},[_c('v-btn',{class:{ 'show-btns': hover, 'hide-btns': !hover },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showForm(_vm.EDIT_MODE, item)}}},[_c('v-icon',{attrs:{"size":"18","color":"primary"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{class:{ 'show-btns': hover, 'hide-btns': !hover },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteConstantFromFlow({ constant: item })}}},[_c('v-icon',{attrs:{"size":"18","color":"error"}},[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }