






















































import { BrickData } from '@/scripts/editor/types';
import { namespaces } from '@/scripts/namespaces';
import {
  API, emptyAPI, emptyEndpoint, Endpoint,
} from '@/store/apis/models';
import { CONFIGURE_APIBRICK, FETCH_APIS, GET_APIS } from '@/store/types';
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class EndpointAndAPI extends Vue {
  // Props
  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop() userdata!: BrickData;

  // Getter
  @Getter(GET_APIS, { namespace: namespaces.API }) getAPIs: any;

  // Actions
  @Action(CONFIGURE_APIBRICK, { namespace: namespaces.FLOW }) configureAPIBrick: any;

  @Action(FETCH_APIS, { namespace: namespaces.API }) fetchApis: any;

  // Data
  private selectedAPI: API = emptyAPI;

  private selectedEndpoint: Endpoint = emptyEndpoint;

  private workspaceId: string = this.$route.params.workspaceId;

  // Computed
  get getEndpoints(): Endpoint[] {
    return this.selectedAPI.endpoints ? this.selectedAPI.endpoints : [];
  }

  // Vue Life Cycle Hooks
  async mounted() {
    await this.fetchApis(this.workspaceId);

    this.intializeModels();
  }

  @Watch('userdata', { deep: true })
  onUserDataChanged() {
    this.intializeModels();
  }

  // Methods
  /**
   * Saves the API for the brick
   */
  async saveEndpointForBrick() {
    try {
      const result = await this.configureAPIBrick({
        apiId: this.selectedEndpoint.apiId,
        endpointId: this.selectedEndpoint.id,
        brickId: this.userdata.instanceId,
      });

      const { DeletedConnections: deletedConnections } : { DeletedConnections: string[] } = result;

      // delete connections from canvas
      if (deletedConnections && deletedConnections.length > 0) {
        this.$emit('delete-connections', deletedConnections);
      }
      this.$emit('update-user-data');
    } catch (error) {
      this.selectedEndpoint = emptyEndpoint;
    }
  }

  /**
   * Checks if the brick already has
   * a selected API and endpoint and
   * sets them to the respective
   * v-models
   */
  intializeModels() {
    const parameterForEndpoint = this.userdata.parameters.find((p) => p.name === 'EndpointID');

    if (parameterForEndpoint && parameterForEndpoint.value) {
      const { value: endpointId } = parameterForEndpoint;

      const endpoint: Endpoint = this.getAPIs
        .flatMap((a: API) => a.endpoints)
        .find((e: Endpoint) => e.id === endpointId);

      if (endpoint) {
        const api = this.getAPIs.find((a: API) => a.id === endpoint.apiId);

        this.selectedAPI = api;
        this.selectedEndpoint = endpoint;
      }
    } else {
      this.selectedAPI = emptyAPI;
      this.selectedEndpoint = emptyEndpoint;
    }
  }

  endpointText = (item: any) => {
    if (!item) return '';
    return `${item.request.method} - ${item.request.path}`;
  }
}
