








































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import * as EditTypes from '@/scripts/editor/types';
import { getBrickIcon } from '@/scripts/bricks/getIcon';
import { Action } from 'vuex-class';
import * as types from '@/store/types';
import { getBrickVersionFromUrn } from '@/scripts/brickVersion';

const namespaceFlow: string = 'flow';

@Component
export default class PropertyPaneHeader extends Vue {
  @Prop() brickData!: EditTypes.BrickData;

  @Prop() brickName!: string;

  @Prop() remainingBricks!: any;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Action(types.UPDATE_BRICK, { namespace: namespaceFlow }) updateBrick: any;

  private editName: boolean = false;

  private brickDescription: string = '';

  getBrickIcon = (brickFamily: string, brickimage: string) => getBrickIcon(brickFamily, brickimage);

  // Created Hook
  created() {
    this.brickDescription = this.brickData.description || '';
  }

  // Watchers
  @Watch('brickData', { immediate: true, deep: true })
  onBrickDataChange() {
    this.brickDescription = this.brickData.description || '';
  }

  get getVersion(): string {
    if (!this.brickData.urn) return '';
    return getBrickVersionFromUrn(this.brickData.urn);
  }

  /**
   * Determines whether the brick name is
   * unique by comparing it to available
   * bricks in the flow
   *
   * @param bricks - The list of names of bricks
   * in the flow
   * @param brickName - The name to compare
   *
   * @returns {number} - The index of the name found
   * in the list or -1 if name is unique
   */
  private isBrickNameUnique = (bricks: string[], brickName: string) => {
    const isUnique = bricks.map((brick) => brick.toLowerCase()).indexOf(brickName.toLowerCase());
    return isUnique;
  };

  private async saveBrickName() {
    this.brickData.newName = this.brickData.newName.trim();

    // Empty names are not allowed
    if (this.brickData.newName === '') {
      return;
    }

    // If brick name is a duplicate of another brick, return
    if (this.isBrickNameUnique(this.remainingBricks, this.brickData.newName) !== -1) {
      this.brickData.newName = this.brickName;
      return;
    }

    if (
      this.brickData.newName !== this.brickName
      || this.brickData.description !== this.brickDescription
    ) {
      this.brickData.name = this.brickData.newName;
      this.brickData.description = this.brickDescription;

      this.$emit('save-name');
      await this.updateBrick({ brick: this.brickData, isShowSuccess: true });
    }
    this.editName = !this.editName;
  }

  /**
   * Turns on the edit mode and sets focus
   * of the textfield/textarea that was
   * clicked
   *
   * @param clickedItem - The clicked span (InstalledBrick name OR InstalledBrick Description)
   */
  private setEditName(clickedItem: string = 'brickName') {
    this.editName = !this.editName;

    // Set focus of the clicked text field
    this.$nextTick(() => {
      const { name, description }: any = this.$refs;
      if (clickedItem === 'brickName') {
        name.$refs.input.focus();
      } else {
        description.$refs.input.focus();
      }
    });
  }

  private cancelEdit() {
    this.editName = false;
    this.brickData.newName = this.brickName;
    this.brickDescription = this.brickData.description || '';
  }
}
