















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Layout extends Vue {
  // include more
}
