var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider',{staticClass:"mx-4"}),_c('div',{staticClass:"d-flex justify-space-between align-center px-4 py-2"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('buffer'))+" ")])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[(_vm.bufferElements.length < 1)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('no_buffer'))+" ")]):_vm._e(),(_vm.getSource && _vm.getSource.name)?[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","onSubmit":"return false;"},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-text-field',{attrs:{"label":_vm.$t('name'),"clearable":"","outlined":"","dense":"","persistent-hint":"","autofocus":"","rules":_vm.rules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveBuffer()}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"disabled":!_vm.bufferName,"color":"primary"},on:{"click":function($event){return _vm.saveBuffer()}}},[_vm._v(" mdi-content-save ")]),_c('v-icon',{attrs:{"color":"warning","right":""},on:{"click":function($event){return _vm.cancelCreateBuffer({})}}},[_vm._v(" mdi-close ")])]},proxy:true}],null,false,3775854693),model:{value:(_vm.bufferName),callback:function ($$v) {_vm.bufferName=$$v},expression:"bufferName"}})],1)],1)],1)]:_vm._e(),_vm._l((_vm.bufferElements),function(bufferElement){return [_c('v-treeview',{key:bufferElement.id,attrs:{"dense":"","item-key":"id","items":[bufferElement.value],"item-children":"elements","hoverable":"","open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"ml-2 pt-0 mt-0 flex-grow-1",attrs:{"dense":"","multiple":"","value":item,"disabled":item.hideCheckBox || _vm.isSelected(item) || _vm.isChildSelected(item) || _vm.isFlowReadOnly,"indeterminate":_vm.isChildSelected(item),"hide-details":""},on:{"click":function($event){return _vm.select(item, bufferElement.id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"subtitle-2 font-weight-regular text-truncate",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text',staticStyle:{"max-width":"10rem"}},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"mx-4 font-italic\n                        grey--text subtitle-2 font-weight-regular text-truncate",staticStyle:{"max-width":"10rem"}},on),[_vm._v("("+_vm._s(item.type)+")")])]}}],null,true)},[_vm._v(" Type is "+_vm._s(item.type)+" ")])]},proxy:true}],null,true),model:{value:(_vm.elementSelection),callback:function ($$v) {_vm.elementSelection=$$v},expression:"elementSelection"}}),_c('v-btn',{class:{ 'show-btns': hover, 'hide-btns': !hover },attrs:{"icon":""},on:{"click":function($event){return _vm.deleteBuffer({ bufferId: bufferElement.id, connectionId: _vm.connection.id })}}},[_c('v-icon',{attrs:{"color":"error","size":"18"}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})]}}],null,true)})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }