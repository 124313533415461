







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { InstalledBrick } from '@/store/bricks/models';
import { logging } from '@/scripts/debugger';
import { getBrickIcon } from '@/scripts/bricks/getIcon';
import { Getter } from 'vuex-class';
import * as types from '@/store/types';
import { getVersionFromUrn } from '@/scripts/brickVersion';

const namespace: string = 'brick';

@Component
export default class BrickDialog extends Vue {
  @Prop() brick!: InstalledBrick;

  @Prop() item!: string;

  @Prop() isInEditor: boolean|undefined;

  @Prop() packageId: string | undefined;

  @Getter(types.GET_PACKAGES, { namespace })
  getPackages: any;

  // eslint-disable-next-line class-methods-use-this
  private log(message: string) {
    logging('BrickDialogue.vue', message);
  }

  private clearBrick() {
    this.log('clearBrick called');
    this.$emit('clear-brick');
  }

  // eslint-disable-next-line class-methods-use-this
  private getBrickIcon(brickFamily: string, brickimage: string = '') {
    return getBrickIcon(brickFamily, brickimage);
  }

  get getVersion(): string {
    if (!this.packageId) return '';
    const findPackage: any = this.getPackages.find((p: any) => p.id === this.packageId);
    return getVersionFromUrn(findPackage.urn);
  }
}
