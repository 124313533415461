



















































































































import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import ConstantBuilder from '@/components/editor/constants/ConstantBuilder.vue';
import { Constant, SchemaType } from '@/scripts/shareModels/schema';
import { CONSTANT_ID_PREFIX } from '@/scripts/shared';
import { findKeyInNestedObject } from '../portMapping/scripts';

const flowNamespace: string = 'flow';

@Component({
  components: {
    ConstantBuilder,
  },
})
export default class ListOfConstants extends Vue {
  // Props
  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop() usedElementsInMapping!: string[];

  // Getters
  @Getter(types.GET_CONSTANTS, { namespace: flowNamespace }) constants: any;

  // Actions
  @Action(types.DELETE_CONSTANT, { namespace: flowNamespace }) deleteConstantFromFlow: any;

  @Action(types.EDIT_CONSTANT, { namespace: flowNamespace }) editConstant: any;

  // Data
  private isAddOrEditMode: boolean = false;

  private constantToEdit: any = '';

  private selectedConstants: Constant[] = [];

  private ADD_MODE: string = 'ADD_MODE';

  private EDIT_MODE: string = 'EDIT_MODE';

  private currentLength: number = 0;

  private parentsWithSelectedChild: string[] = [];

  // Watchers

  @Watch('usedElementsInMapping', { deep: true })
  onUsedElementsChanged() {
    this.parentsWithSelectedChild.splice(0, this.parentsWithSelectedChild.length);
    this.updateSelectedConstants();
  }

  // Created Hook
  created() {
    this.updateSelectedConstants();
  }

  // Methods

  /**
   * Finds out which constants of
   * the brick are already selected
   */
  updateSelectedConstants() {
    // check constants that are selected at root level
    this.selectedConstants = this.constants.filter((c: Constant) => this.isConstantSelected(c));

    // check selected constants that are in the elements of constants
    this.constants
      .filter((c: Constant) => c.elements && c.elements.length > 0)
      .forEach((c: Constant) => {
        this.usedElementsInMapping
          .filter((e) => e.includes(CONSTANT_ID_PREFIX))
          .forEach((usedElement) => {
            const result = findKeyInNestedObject(c, usedElement);

            if (result) {
              const { child, parent } = result;

              this.selectedConstants.push(child as Constant);

              if (parent && parent.id) {
                this.parentsWithSelectedChild.push(parent.id);
              }
            }
          });
      });

    this.currentLength = this.selectedConstants.length;
  }

  /**
   * Makes the form for adding
   * a constant visible
   */
  showForm(operation: string, constant: Constant) {
    if (operation === this.ADD_MODE) {
      this.constantToEdit = '';
    } else {
      const constantToEdit = constant;
      constantToEdit.hideCheckBox = this.isConstantSelected(constant);
      this.constantToEdit = constantToEdit;
    }

    this.isAddOrEditMode = true;
  }

  /**
   * Selects a constant and informs the MappingDialog
   * to keep it ready for a possible mapping rule
   */
  select(constant: Constant) {
    const { name } = constant;

    // Uncheck type if something is already checked
    // So that only one type can be checked at a time
    if (this.selectedConstants.length - this.currentLength > 1) {
      this.selectedConstants.splice(this.selectedConstants.length - 2, 1);
    }

    this.$emit(
      'selected',
      this.selectedConstants.map((selection) => selection.name).includes(name) ? constant : null,
    );
  }

  /**
   * Finds out if given constant
   * is selected so that it can be shown
   * as checked
   */
  isConstantSelected(constant: Constant) {
    if (constant.id) {
      return this.usedElementsInMapping.includes(constant.id);
    }

    return false;
  }

  /**
   * Given a constant, checks if one of
   * its children is selected
   */
  isChildSelected(constant: Constant) {
    if (constant.id) {
      return this.parentsWithSelectedChild.includes(constant.id);
    }

    return false;
  }

  /**
   * Closes the dialog for ADD or EDIT
   * and updates the selected constants
   * to avoid v-checkbox glitches
   */
  closeDialog() {
    this.isAddOrEditMode = false;
    this.updateSelectedConstants();
  }
}
