










































import { Component, Prop, Vue } from 'vue-property-decorator';
import EditDraw2d from '@/components/editor/EditDraw2d.vue';
import Layout from '@/components/shared/Layout.vue';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';

const namespace: string = 'flow';

// eslint-disable-next-line no-use-before-define
@Component<Editor>({
  components: {
    EditDraw2d,
    Layout,
  },

  // eslint-disable-next-line class-methods-use-this
  beforeRouteLeave(to, __, next) {
    if (this.getFlowIsDirty && !this.skipSave) {
      this.dialog = true;
      this.toRoute = to;
      return;
    }
    next();
  },
})
export default class Editor extends Vue {
  @Getter(types.GET_IS_DIRTY, { namespace }) getFlowIsDirty: any;

  private dialog: boolean = false;

  private toRoute: any = '';

  private skipSave: boolean = false;

  ignoreFlowChanges() {
    this.dialog = false;
    this.skipSave = true;

    // change the isDirty state to true
    this.$store.dispatch('flow/SET_FLOW_IS_DIRTY', false, { root: true });

    this.$router.push({ path: this.toRoute.path });
  }
}
