










































































import { Component, Vue, Prop } from 'vue-property-decorator';
import PortEditor from '@/components/editor/PortEditor.vue';
import * as EditTypes from '@/scripts/editor/types';

@Component({
  components: {
    PortEditor,
  },
})
export default class PortDescription extends Vue {
  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop() userdata!: EditTypes.BrickData;

  @Prop() portData: any;

  // Data
  private editPortDialog: boolean = false;
}
