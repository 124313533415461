











































































import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { Action } from 'vuex-class';
import * as storeTypes from '@/store/types';
import { Connection, MappedElement } from '@/store/flow/models';
import { CONSTANT_MAPPING_RULE } from '@/scripts/shared';

const flowNamespace: string = 'flow';

@Component
export default class MappedElements extends Vue {
  // Props
  @Prop() mappedElement!: MappedElement;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop() connection!: Connection;

  // Actions
  @Action(storeTypes.ADD_PORT_MAPPING, { namespace: flowNamespace }) addPortMapping: any;

  @Action(storeTypes.DELETE_PORT_MAPPING, { namespace: flowNamespace }) deletePortMapping: any;

  // Datas
  private CONSTANT_MAPPING_RULE = CONSTANT_MAPPING_RULE;
  // Computed

  get mappingRules(): any {
    return this.connection?.mapping.map((element) => element.rule);
  }

  // Watch
  @Watch('mappedElement', { deep: true })
  onMappingRuleReceived() {
    const { rule, usedElements } = this.mappedElement;

    if (rule.sourceFields.length > 0 && usedElements.length > 0) {
      this.addPortMapping({
        connection: this.connection,
        mappedElement: this.mappedElement,
      });

      // empty the mapped element
      this.$emit('empty-mapped');
    }
  }

  // Methods

  deleteMapping(index: number) {
    this.deletePortMapping({
      connection: this.connection,
      index,
    });
  }

  // Vue Life cycle
}
